import service from "@/http/request";

// 查询应用列表
export const getAppIdList = (data) => {
  return service({
    url: "/api/getAppIdList",
    method: "post",
    data
  });
};
// 修改应用状态
export const updateAppStatus = (data) => {
  return service({
    url: "/api/updateAppStatus",
    method: "post",
    data
  });
};